import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken} from '../prismic-configuration'
import GoogleMapReact from 'google-map-react';


const Container = styled.div`
width: 95%;
padding: 30px 2.5%;
background-color: #E5DBCF;
color: var(--maroon);
a{
  color: var(--orange);
}
h2{
  font-size: 42px;

  @media(max-width: 600px){
    text-align: center;
  }
}
`

const MapHold = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin-top: 15px;
flex-wrap: wrap;

@media(max-width: 600px){
}
div{
  width: calc(50% - 15px);
  @media(max-width: 600px){
    width: 100%;
    margin-bottom: 15px;
  }


  img{
    width: 100%;
    object-fit: cover;
  }

  h3{
    font-size: 22px;
    margin-top: 15px;
  }

  p{
    font-size: 14px;
    margin: 0;
    line-height: 20px;
    font-weight: 600;
  }
}

`

const Marker = styled.h3`
width: 62px;
height: 62px;
background-size: contain;
background-repeat: no-repeat;
`




const Maps = () =>{
  const [prismicData, setPrismicData] = useState({ homePage: null });
  const english = localStorage.getItem('eng')

  // Get the homepage document from Prismic
  useEffect(() => {

    const fetchPrismicData = async () => {
      try {
        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getSingle('homepage', options);
        const defaultPage = await Client.getSingle('homepage', {lang: 'en-us'});

        let homeDoc;
        if(pageToggle === undefined){
          homeDoc = defaultPage;
        }else{
          homeDoc = pageToggle;
        }

        if (homeDoc) {
          setPrismicData({ homeDoc});
        } else {
          console.warn('Homepage document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.homeDoc) {

    const homeDoc = prismicData.homeDoc;

    const mappies = homeDoc.data.visit_us.map(function(reference, index) {



      const location = reference.googlemaps;

      const lat = location.latitude;  // latitude
      const long = location.longitude; // longitude

      return (
        <div>
        <div style={{height: '300px', width: '100%'}}>
        <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDe_Qr_oZ2cBliXpz8SFYtrmAgKOkYVWsQ" }}
              defaultCenter={{
          lat: lat,
          lng: long
        }}
              defaultZoom={15}
            >
          <Marker
          style={{ backgroundImage: `url(${reference.map_icon.url})`} }
          lat={lat}
          lng={long}
          ></Marker>
            </GoogleMapReact>
        </div>
        <h3>{reference.location_name}</h3>
        {RichText.render(reference.address)}
        </div>

      )
    });

    return(
    <Container>
    <h2>{english==='true' ? 'Visit Us' : 'Visítanos'}</h2>

    <MapHold>
    {mappies}
    </MapHold>
    </Container>

    )

  }else return null
}

export default Maps
