import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';
import { Date, Link, RichText } from 'prismic-reactjs';
import { Client, apiEndpoint, accessToken} from '../prismic-configuration';
import MailchimpSubscribe from "react-mailchimp-subscribe"

const url = "//centrocultural.us7.list-manage.com/subscribe/post?u=fa05f03020ac4167d6f24237b&amp;id=d976bfb7e6";




export const Mailchimp = styled.div`
  margin: 10px 0;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 4% 0 0;
  @media(max-width: 700px){
    width: 100%;
  }

  input{
    width: 100%;
    padding: 10px;
    font-family: "Open Sans";
    font-weight: 600;
    margin: 10px 0 0;
  border-radius: 8px;
  border: none;
  background-color: var(--cream);
    font-family: 'Open sans';
    font-size: 16px;
    letter-spacing: 1px;
    color: var(--maroon) ;
    ::placeholder{
      opacity: 1;
      color: var(--orange);
    }
  }

  button{
    padding: 10px 20px;
    margin: 10px 0 0;
    border-radius: 8px;
    border: none;
    background-left: 2px solid;
    color: var(--cream);
    font-family: "Bebas Neue";
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: var(--orange);
    opacity: 1;
    transition: 0.5s;
    :hover{
          background-color: var(--yellow);

    }
  }
  div{
    display: flex;
    width: 100%;
    input + input{
      margin-left: 10px;
    }
  }
`

export const MailchimpText = styled.div`
font-family: beaufort;
letter-spacing: 1px;
color: var(--cream);
width: 100%;
margin: 0 !important;
p{
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: var(--cream) !important;
  font-weight: normal !important;
  font-family: "Open Sans";
  @media(max-width: 700px){
    text-align: center;
    margin: 0 auto;
  }
}
`

export const MailchimpClear = styled.div`
width: 100vw;
position: relative;
left: -2vw;
`
export const MailchimpClear2 = styled.div`
width: 100vw;
position: relative;
left: 0vw;
`

export const MailchimpHold = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
margin: 30px 0;
left: -2vw;
background-color: var(--maroon);
padding: 60px 2vw;
position: relative;
@media(max-width: 700px){
  flex-wrap: wrap;
}

@media(max-width: 600px){
  left: -5vw;
  padding: 60px 5vw;
}
`

export const McInfo = styled.div`
color: var(--cream);
width: 45%;
font-weight: normal;
padding: 0 2% 0 4%;
@media(max-width: 700px){
  width: 100%;
}
h3{
  font-size: 42px;
  margin: 0;
  color: var(--yellow);
}

p{
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  color: var(--rust);
  margin: 0;
}
`

export const Squares = styled.div`
width: 100%;
background-color: var(--maroon);
mask: url('square.svg');
fill: var(--cream);
height: 20px;
position: absolute;
top: -20px;
left: 0;

`

export const Squares2 = styled.div`
width: 100%;
background-color: var(--maroon);
mask: url('square2.svg');
fill: var(--cream);
height: 20px;
position: absolute;
bottom: -20px;
left: 0;
`




const CustomForm = ({ status, message, onValidated }) => {
  let email;
  let fname;
  let lname;
  const submit = () =>
    email &&
    fname &&
    lname &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      FNAME: fname.value,
      LNAME: lname.value
    });

  return (
    <MailchimpClear>
    <MailchimpHold>
    <Squares/>
    <McInfo>
    <h3>Join Our Mailing List</h3>

    <p>We'll periodically send you the latest information about what's happening at Centro, including volunteer opportunities, events, and other news.</p>
    </McInfo>

    <Mailchimp>
    <MailchimpText>
        {status === "sending" && <p>sending...</p>}
        {status === "error" && (
          <p dangerouslySetInnerHTML={{ __html: message }}/>
        )}
        {status === "success" && (
          <p
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
          {status === null && null}
          </MailchimpText>
          <div>
      <input
        ref={node => (fname = node)}
        type="text"
        placeholder="First Name"
      />
      <input
        ref={node => (lname = node)}
        type="text"
        placeholder="Last Name"
      />
      </div>
      <input
        ref={node => (email = node)}
        type="email"
        placeholder="Your email"
      />
      <button onClick={submit}>
        Submit
      </button>
      </Mailchimp>

    </MailchimpHold>
    </MailchimpClear>
  );
};



const MailBlock = () => {
return(
  <MailchimpSubscribe
  url={url}
  render={({ subscribe, status, message }) => (
    <CustomForm
      status={status}
      message={message}
      onValidated={formData => subscribe(formData)}
    />
  )}
  />

);
};

export default MailBlock;
