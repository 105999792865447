import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';


const Container = styled.div`
position: absolute;
z-index: 1000;
display: flex;
justify-content: center;
width: 100%;
height: calc(100% - 120px);
align-items: center;
opacity: 100%;
transition: 1s linear;

&.fade{
  opacity: 0;
}
`

const Fill = styled.div`
position: fixed;
background-color: var(--cream);
top: 0; right: 0; bottom: 0; left: 0;
opacity: 0.75;
`

const Text = styled.div`
position: absolute;
width: 50%;
background-color: var(--maroon);
padding: 30px;
color: var(--cream);
z-index: 0;
display: flex;
flex-wrap: wrap;
justify-content: center;

@media(max-width: 750px){
  width: 80%;
}
h2{
  font-size: 48px;
  text-align: center;
  width: 100%;
  line-height: 52px;
  margin: 0 0 20px;
}

p{
  color: var(--yellow);
  font-size: 18px;
  margin: 0 0 10px;
  text-align: center;
  width: 100%;
}

a{
  margin: 20px 0 0;
  :hover{
    cursor: pointer;
  }

}

button{
  color: var(--orange);
  font-family: 'Bebas Neue';
  font-size: 24px;
  margin: 0px 40px 20px 30px;
  border: none;
  line-height: 30px;
  position: relative;
  transition: .5s ease-in;
  float: left;
  font-weight: normal;
  strong{
    font-weight: normal;
  }

  :before{
    padding: 15px 10px;
    background-color: var(--orange);
    display: inline-block;
    content: ">";
    border-radius: 15px;
    color: var(--cream);
    transition: .5s ease-in;
    width: 10px;
    line-height: 0;
    position: absolute;
    z-index: -1;
    left: -35px;



  }

  :hover{
    color: var(--cream);
    :before{
      width: calc(100% + 30px);
      transition: .5s ease-in;
    height: inherit;      }

  }



  @media(max-width: 800px){
    font-size: 18px;
      :before{
        top: -2px;
      }
      span{
        top: -2px;
      }
  }
}
`

export const Squares = styled.div`
width: 100%;
background-color: var(--maroon);
mask: url('square.svg');
fill: var(--cream);
height: 20px;
position: absolute;
top: -20px;
left: 0;

`

export const Squares2 = styled.div`
width: 100%;
background-color: var(--maroon);
mask: url('square2.svg');
fill: var(--cream);
height: 20px;
position: absolute;
bottom: -20px;
left: 0;
`


const Popup = () => {

const [button, clickButton] = useState(false);
const [gone, checkGone] = useState(false);


const clicky = () => {
  clickButton(true);
  setTimeout(() => {checkGone(true)}, 1000);
  setTimeout(() => {localStorage.setItem('noPop', true)}, 1000);

}

const checkPop = localStorage.getItem('noPop');

return(
  <>
  {checkPop ? null :
  <>
  {gone ? null :

  <Container className={`${button === true ? 'fade' : ''} buttons`}>
  <Fill/>
  <Text>
  <Squares/>
  <h2>Welcome to our new website! </h2>
  <h2>¡Bienvenidos a nuestro sitio nuevo!</h2>
  <p>Thank you for being a part of #NuestroCentro.</p>
  <a onClick={clicky}>continue to the site</a>
  <Squares2/>
  </Text>
  </Container>
  }
  </>
}

  </>
)
}

export default Popup;
