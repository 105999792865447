import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken} from '../prismic-configuration'
import {SliceZoneNavSmall} from './slicezone';

export const NavigationSmall = styled.div`
width: 100%;
border-bottom: 2px solid var(--maroon);
transition: 0.5s linear;
overflow: hidden;
position: fixed;
background-color: var(--cream);
z-index: 150;
display: flex;
justify-content: space-between;
flex-wrap: wrap;

ul{
  width: 100%;
  text-align: center;
  li{
    font-family: 'Open Sans';
    color: var(--orange);
    margin: 9px 0;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
}
`
export const LogoSmall = styled.img`
height: 50px;
margin-top: 26px;

`
const SpacerSmall = styled.div`
height: 100px;
`

export const HoldSmall = styled.div`
width: calc(100% - 40px);
display: flex;
flex-wrap: wrap;
// margin: 0 auto 10px;
margin: 0 auto;
justify-content: space-between;
align-items: center;
h3{
  display: flex;
  text-align: center;
  line-height: 20px;
  height: fit-content;
  margin: 10px auto 0;
  width: fit-content;
}

ul{
padding-left: 0;
margin: 0 10px;
font-weight: 600;


@media(max-width: 900px){
  font-size: 15px;
}
}

`

export const HoldSmallSub = styled(HoldSmall)`
flex-wrap: wrap;
height: calc(100vh - 102px);
overflow-y: scroll;
max-height: 0;
transition: 0.5s linear;

`

export const Chunk = styled.div`
height: 30px;
width: 100%;
`

export const TranslatorSmall = styled.div`
position: relative;
bottom: -4px;
right: 0;
display: flex;
color: var(--maroon);
width: 100%;
clear: both;
justify-content: center;
padding-bottom: 30px;
margin-top: 30px;
p{
  margin: 5px 5px 0 0;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  text-transform: uppercase;
}

.buttons{

}

.highlighted{
  background-color: var(--maroon);
  color: var(--cream);
}

button{
  border: none;
  margin: 0;
  padding: 4px 5px 4px;
  border-radius: 20px;
  font-size: 14px;
 outline:none;
 color: var(--maroon);
 font-weight: 600;
 font-family: 'Open Sans';
  :hover{
    cursor: pointer;
  }

  :focus{
    border: none;
  }
}

`


const NavSmall = () => {


  const [prismicData, setPrismicData] = useState({ navigation: null });

  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const navigation = await Client.getSingle('navigation');

        if (navigation) {
          setPrismicData({ navigation});
        } else {
          console.warn('Nav document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.navigation) {

    const nav = prismicData.navigation;



  return(
<div>


  <SliceZoneNavSmall/>


  <SpacerSmall/>
</div>
)}else return null
}

export default NavSmall;
