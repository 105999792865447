import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState, setState, useRef} from 'react';
import {
  TopBlock, MainBlock, HalfBlocks,
  BigBlock, ThreeBlocks, VideoBlock,
  GlobalStyle, Overlap, Paragraph,
  Report} from './styles.js';
import {TopHold, TopBar, TopImg} from './homepage';
import Video from './video.js';
import Prismic from '@prismicio/client'
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken} from '../prismic-configuration';
import {VideoContain, VideoText, Vid} from './video.js';
import ReactPlayer from 'react-player'
import ImageGallery from 'react-image-gallery';
import scrollToComponent from 'react-scroll-to-component';
import 'react-image-gallery/styles/css/image-gallery.css';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {MailchimpHold, McInfo, Mailchimp, MailchimpText, Squares, Squares2, MailchimpClear2} from './mailChimpBlock.js';
import {FacebookHold, FbInfo, Facebook, FacebookText, FacebookClear} from './facebookBlock.js';
import Iframe from 'react-iframe'

import ScriptTag from 'react-script-tag';


export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'program') {
    return `/${doc.uid}`
  }

  else if (doc.type === 'team'){
    return `/team/${doc.uid}`
  }
  else if (doc.type === 'blog_index'){
    return `/blog`
  }
  // Backup for all other types
  return '/'
}

const url = "//centrocultural.us7.list-manage.com/subscribe/post?u=fa05f03020ac4167d6f24237b&amp;id=d976bfb7e6";


const TopImgProg = styled.img`
width: 100%;
margin: 0;
max-height: 500px;
object-fit: cover;
max-width: 100%;
display: block;

@media(max-width: 500px){
  object-fit: scale-down;
}
`

const Program = ({ match }) => {
  const [prismicData, setPrismicData] = useState({ pageDoc: null});
  const [notFound, toggleNotFound] = useState(false);
  const uid = match.params.uid;
  const english = localStorage.getItem('eng')
  // Get the page document from Prismic
  React.useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getByUID('program', uid, options);
        const defaultPage = await Client.getByUID('program', uid, {lang: 'en-us'});

        let pageDoc;
        if(pageToggle === undefined){
          pageDoc = defaultPage;
        }else{
          pageDoc = pageToggle;
        }

        if (pageDoc) {
          setPrismicData({ pageDoc });
        }


        else {
          console.warn('Page document was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, [uid]);

  if (prismicData.pageDoc) {

    const prog = prismicData.pageDoc;
    const hasVid = Link.url()
    let hasImg;

    if(prog.data.top_video.url){
      hasImg =
      <div className={'player-wrapper'}>
      <ReactPlayer
        className={'react-player'}
        url='https://www.youtube.com/watch?v=SYACA-0WYts&t=2s'
        width='100%'
        height='100%'
        config={{
          youtube: {
            playerVars: { modestbranding: 1 }
          }
        }}/></div>

    }
    else if(prog.data.top_image.url){
      hasImg = <TopImgProg src={prog.data.top_image.url}/>
    }
    else{
      hasImg = null
    }
    let topcontent;

    const navSlices=prog.data.body.map((slice, index) => {
      if(slice.primary.nav_text){return(
        <h3 className={'qj'} onClick={() => scrollToComponent(slice.primary.nav_text, { offset: -110, align: 'top', duration: 30})}>{slice.primary.nav_text}</h3>
      )}else return null
    });


    const pageSlices=prog.data.body.map((slice, index) => {
  // Render the right markup for the given slice type

  // Text Slice
  if (slice.slice_type === '2_blocks') {
    console.log(slice.primary.sms_text_2);
    console.log(slice.primary.sms_number_2);

    let smsCheck;
    let smsCheck2;

    if(slice.primary.sms_number_2 === null || ''){
      smsCheck2 = 'hide'
    }else{
      smsCheck2 = 'show'
    }

    if(slice.primary.sms_number === null || ''){
      smsCheck = 'hide'
    }else{
      smsCheck = 'show'
    }

    console.log(smsCheck2);
    console.log(smsCheck);

    return (
      <HalfBlocks className={'buttons2'} ref={(section) => { slice.primary.nav_text = section; }}>
        <div className={'halfBlock'}>
        <img src={slice.primary.image_1.url}></img>
        <h3>{RichText.asText(slice.primary.header_1)}</h3>
        {RichText.render(slice.primary.text_1, linkResolver)}
        {smsCheck === 'show' && <h6><a href={`sms://` + slice.primary.sms_number}>{slice.primary.sms_text}</a></h6>}
      </div>
        <div className={'halfBlock'}>
        <img src={slice.primary.image_2.url}></img>
        <h3>{RichText.asText(slice.primary.header_2)}</h3>
        {RichText.render(slice.primary.text_3, linkResolver)}
        {smsCheck2 === 'show'  && <h6><a href={`sms://` + slice.primary.sms_number_2}>{slice.primary.sms_text_2}</a></h6>}
      </div>
      </HalfBlocks>
);

  }

  // Big Head Slice
else if(slice.slice_type === 'section_title'){
  return(
    <div className={'bigHead'} ref={(section) => { slice.primary.nav_text = section; }}><span/><h2>{RichText.asText(slice.primary.header)}</h2><span/></div>
  )

}

//Feature block
else if(slice.slice_type === 'feature_block'){
  return(
    <BigBlock className={'buttons2 full'} ref={(section) => { slice.primary.nav_text = section; }}>
      <img src={slice.primary.image.url}/>
      <div className={'text'}>
      <h4>{RichText.asText(slice.primary.label)}</h4>
      <h3>{RichText.asText(slice.primary.header)}</h3>
    {RichText.render(slice.primary.text, linkResolver)}
        </div>
    </BigBlock>
  )
}

//small 2_blocks
  else if (slice.slice_type === '3_blocks'){
    const checkLink = Link.url(slice.primary.link).length;


    const block = slice.items.map((block, blockIndex) => (
      <div className={'threeCard'} key={blockIndex}>
      <div className={'imghold'}><img src={block.image.url} /></div>
      <div className={'texthold'}>
        <h4>{block.header}</h4>

        {RichText.render(block.text, linkResolver)}
        {block.link_text ? <a href={Link.url(block.link, linkResolver)} className={'button2'} target={block.link.target}><span/>{block.link_text}</a> : null}

      </div></div>
       ));

    return(


    <ThreeBlocks className={'spacee'} ref={(section) => { slice.primary.nav_text = section; }}>
    <h3 className={'subheadtext'}>{slice.primary.section_head}</h3>
    {block}
    <div className="floatButton">
    {checkLink > 0 ? <a href={Link.url(slice.primary.link, linkResolver)} className={'button1'} target={slice.primary.link.target}>{slice.primary.link_text}</a> : null}
    </div>
      </ThreeBlocks>
    )
  }

  else if (slice.slice_type === 'button'){
    const ButtonHold = styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
    `

    var target = {};
 if (slice.primary.link.target) {
   target = {
     target: slice.primary.link.target,
     rel: "noopener"
   };
 }
    return(


      <ButtonHold>
      <a href={Link.url(slice.primary.link, linkResolver)}  {...target} className={'button1'}>{slice.primary.link_text}</a>
      </ButtonHold>
    );
  }

  else if (slice.slice_type === 'youtube'){

    const VidToggle = () => {
    const [show, toggleShow] = React.useState(false);

    return(
      <VideoContain ref={(section) => { slice.primary.nav_text = section; }}>
      <VideoText className={show && 'hideText'}>
      <div className={'buttons'}>
        <h3>{RichText.asText(slice.primary.header)}</h3>
        {RichText.render(slice.primary.links, linkResolver)}
        </div>
      </VideoText>
      <Vid className={show && 'vidExpand'}>
      <ReactPlayer
        className={'react-player'}
        url={slice.primary.video.url}
        width='100%'
        height='100%'
        onPlay={() => toggleShow(!show)}
        onPause={() => toggleShow(!show)}
        config={{
          youtube: {
            playerVars: { modestbranding: 1 }
          }
        }}/>
      </Vid>
      </VideoContain>
    )}

    return(<VidToggle/>)
  }



  else if (slice.slice_type === 'overlap_image'){
    return(
      <Overlap className={'buttons2'} ref={(section) => { slice.primary.nav_text = section; }}>
      <img src={slice.primary.image.url}/>
      <div>
      {RichText.render(slice.primary.text, linkResolver)}
      </div>
      </Overlap>
    );
  }

  else if (slice.slice_type === 'paragraph'){
    const paragraphname = 'test'
    return(
      <Paragraph className={'buttons2'}ref={(section) => { slice.primary.nav_text = section; }}>
      {RichText.render(slice.primary.text, linkResolver)}
      </Paragraph>
    )
  }

  else if (slice.slice_type === 'gallery'){
    const images = slice.items.map((slide, slideIndex) => (
      {
    original: slide.image.url,
    thumbnail: slide.image.thumb.url,
  }
    ));
    return(

      <ImageGallery items={images}
      showFullscreenButton={false}
      showPlayButton={false}/>

    )
  }

  else if (slice.slice_type === 'report'){

    const linkText = slice.primary.link_text
    let checkLink
    if(linkText === null){
      checkLink = 'Read the Report'
    }else if(linkText.length < 2){
      checkLink = 'Read the Report'
    }else{
      checkLink = slice.primary.link_text
    }

    return(
      <Report ref={(section) => { slice.primary.nav_text = section; }}>
      <img src={slice.primary.cover.url}/>
      <div className={'buttons'}>
      {RichText.render(slice.primary.text, linkResolver)}
      <a href={Link.url(slice.primary.report_link, linkResolver)} {...target}>{checkLink}</a>
      </div>
      </Report>
    );
  }

  else if (slice.slice_type === 'faq'){

    const Faq = styled.div`
    color: var(--maroon);
      p{
        width: 66%;
        margin: 0;
      }

      h5{
        font-family: 'Open Sans';
font-weight: 600;
font-size: 18px;
margin: 15px 0 5px;

      }

      @media(max-width: 600px){
        p{
          width: 100%;
        }
      }

    `

    const TeamTop = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 0;
    h3{
      width: 33%;

      font-size: 24px;
    }
    h4{
      width: calc(66% - 45px);
      font-family: 'Open Sans';
      font-weight: 600;
    }
    button{
      border: none;
      font-family: 'Bebas Neue';
      font-size: 28px;
      line-height:36px;
      color: #fff;
      width: 35px;
      height: 35px;
      text-align: center;
      border-radius: 100px;
      background-color: var(--orange);
      transform: rotate(270deg);
      transition: 0.15s linear;

      :hover{
        background-color: var(--yellow);
        cursor: pointer;
      }

      :focus{
        outline: none;
      }

      img{
        width: 33%;

      }
    }

    @media(max-width: 600px){
      h4{
        width: 80%;
      }
    }
    `

    const TeamBottom = styled(TeamTop)`
    align-items: start;
    transition: 0.5s linear;
    max-height: auto;
    overflow: hidden;
    img{
      width: 27%;
      margin-bottom: 10px;
    }

    `

    const Question = styled.div`
    width: 100%;
    border-bottom: 1px solid;

    h4{
      font-family: 'Bebas neue';
      font-weight: normal;
      font-size: 24px;
    }
    `

    const Text = styled.div`
    p{
      margin: 0 0 10px;

    }

    a{
      color: var(--orange);
      font-weight: 600;
    }
    `

    const Questions = styled.div`
      margin-top: 15px;
    `

    const block = slice.items.map((block, blockIndex) =>{
      const Toggle = () => {
        const [show, toggleShow] = React.useState(true);

      return(
      <Question>
      <TeamTop>
      <h4>{block.question}</h4>
      <button className={show && 'buttonSpin'} onClick={() => toggleShow(!show)} >></button>
      </TeamTop>
      <TeamBottom className={show && 'teamHide'}>
      <Text>
      {RichText.render(block.answer, linkResolver)}
      </Text>
      </TeamBottom>
      </Question>
      )
      }

      return(
        <Toggle/>
      )
    });

    return(
      <Faq>
      <h2>{slice.primary.header}</h2>
      {RichText.render(slice.primary.text, linkResolver)}
      <Questions>
      {block}
      </Questions>
      </Faq>
    );
  }
  else if (slice.slice_type === 'mailchimp'){
    const CustomForm = ({ status, message, onValidated }) => {
      let email;
      let fname;
      let lname;
      const submit = () =>
        email &&
        fname &&
        lname &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
          FNAME: fname.value,
          LNAME: lname.value
        });

      return (
        <MailchimpClear2>
        <MailchimpHold>
        <Squares/>
        <McInfo>
        <h3>{slice.primary.header}</h3>
        {RichText.render(slice.primary.paragraph, linkResolver)}
        </McInfo>

        <Mailchimp>
        <MailchimpText>
            {status === "sending" && <p>{slice.primary.sending}</p>}
            {status === "error" && (
              <p>{slice.primary.error}</p>
            )}
            {status === "success" && (
              <p>{slice.primary.success}</p>
            )}
              {status === null && <p>{slice.primary.default_text}</p>}
              </MailchimpText>
              <div>
          <input
            ref={node => (fname = node)}
            type="text"
            placeholder="First Name"
          />
          <input
            ref={node => (lname = node)}
            type="text"
            placeholder="Last Name"
          />
          </div>
          <input
            ref={node => (email = node)}
            type="email"
            placeholder="Your email"
          />
          <button onClick={submit}>
            Submit
          </button>
          </Mailchimp>
          <Squares2/>
        </MailchimpHold>
        </MailchimpClear2>
      );
    };

    return(<MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
    />)

  }

  else if (slice.slice_type === 'facebook_embed'){
    return(
      <FacebookClear className="buttons2">
      <FacebookHold>
      <Squares/>
      <FbInfo>
      <h3>{slice.primary.header}</h3>

      {RichText.render(slice.primary.paragraph, linkResolver)}
      </FbInfo>

      <Facebook>
      {slice.primary.page_toggle ?
        <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FProsperidadCentro%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=833893947539216" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>
        :
        <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcentrocultural.org&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=833893947539216" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>

      }
        </Facebook>
        <Squares2/>
      </FacebookHold>
      </FacebookClear>
    )
  }

  else if(slice.slice_type === 'donate_button'){
    const KindHold = styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
    `

      return(
        <KindHold>
        <a className={'kindfulButton'} id={`kindful-donate-btn-` + slice.primary.id}>Donate</a>
  <ScriptTag src={`https://centrocultural.kindful.com/embeds/` + slice.primary.id + `/init.js`} data-embed-id={slice.primary.id} data-lookup-type='jquery-selector' data-lookup-value={`#kindful-donate-btn-` + slice.primary.id}></ScriptTag>
      </KindHold>
      ) }
    else {
    return null;
  }
});



    return(
      <div>
      <GlobalStyle/>
      <TopBlock>
      {hasImg}

      </TopBlock>
      <MainBlock>


      <div className={'bigHead'}><span/><h2>{prog.data.page_title}</h2><span/></div>
      <div className={'introText buttons'}>{RichText.render(prog.data.page_intro, linkResolver)}</div>
      {prog.data.quick_jump && <div className="quickjump">
      <h3>Quick Jump:</h3>
      {navSlices}
      </div>}
      {pageSlices}
      </MainBlock>
      </div>);
  } return(null);

}

export default Program;
