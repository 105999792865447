import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState, setState, useRef} from 'react';
import {
  TopBlock, MainBlock, HalfBlocks,
  BigBlock, ThreeBlocks, VideoBlock,
  GlobalStyle, Overlap, Paragraph, BlogParagraph,
  Report} from './styles.js';
import {TopHold, TopBar, TopImg} from './homepage';
import Video from './video.js';
import Prismic from '@prismicio/client'
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken, linkResolver} from '../prismic-configuration';
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import MailchimpSubscribe from "react-mailchimp-subscribe";
import {MailchimpHold, McInfo, Mailchimp, MailchimpText, Squares, Squares2, MailchimpClear2} from './mailChimpBlock.js';

const url = "//centrocultural.us7.list-manage.com/subscribe/post?u=fa05f03020ac4167d6f24237b&amp;id=d976bfb7e6";


const BlogPara = styled.div`
p{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}`

const SmallerHold = styled.div`
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
`
const LilBlock = styled.div`
width: 30%;
margin-left: 2.5%;
color: var(--maroon);
h3{
  font-size: 32px;
  margin: 5px 0;
}
img{
  width: 100%;
  height: 60%;
  object-fit: cover;
}
`

const LilDate = styled.h4`
color: var(--yellow);
font-size: 18px;
margin-bottom: 5px;
`




const BlogIndex = ({ match }) => {
  const [prismicData, setPrismicData] = useState({ pageDoc: null, blogPosts: null });
  const [notFound, toggleNotFound] = useState(false);

  // Get the page document from Prismic
  React.useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const pageDoc = await Client.getSingle('blog_index');
        const blogPosts = await Client.query(
          Prismic.Predicates.at('document.type', 'blog'),
          {orderings: '[my.post.date desc]'}
        );

        if(pageDoc){
          setPrismicData({pageDoc, blogPosts: blogPosts.results});
        }

      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, []);

  if (prismicData.pageDoc) {
    const blog = prismicData.pageDoc;
    const posts = prismicData.blogPosts;

    const indexSlices=blog.data.body.map((slice, index) =>{
      if(slice.slice_type === 'mailchimp') {
        const CustomForm = ({ status, message, onValidated }) => {
          let email;
          let fname;
          let lname;
          const submit = () =>
            email &&
            fname &&
            lname &&
            email.value.indexOf("@") > -1 &&
            onValidated({
              EMAIL: email.value,
              FNAME: fname.value,
              LNAME: lname.value
            });

          return (
            <MailchimpClear2>
            <MailchimpHold>
            <Squares/>
            <McInfo>
            <h3>{slice.primary.header1}</h3>
            {RichText.render(slice.primary.paragraph1)}
            </McInfo>

            <Mailchimp>
            <MailchimpText>
                {status === "sending" && <p>{slice.primary.sending}</p>}
                {status === "error" && (
                  <p>{slice.primary.error}</p>
                )}
                {status === "success" && (
                  <p>{slice.primary.success}</p>
                )}
                  {status === null && <p>{slice.primary.default_text}</p>}
                  </MailchimpText>
                  <div>
              <input
                ref={node => (fname = node)}
                type="text"
                placeholder="First Name"
              />
              <input
                ref={node => (lname = node)}
                type="text"
                placeholder="Last Name"
              />
              </div>
              <input
                ref={node => (email = node)}
                type="email"
                placeholder="Your email"
              />
              <button onClick={submit}>
                Submit
              </button>
              </Mailchimp>
              <Squares2/>
            </MailchimpHold>
            </MailchimpClear2>
          );
        };

        return(<MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={formData => subscribe(formData)}
          />
        )}
        />)
      }
    })


    const postMap = posts.map(function(blog, index){
      const today = Date(blog.data.published_date);
      const formattedDate = format(today, 'PP');
      return(
        <div>
        {index === 0 ?
          <BigBlock className={'buttons'}>
            <img src={blog.data.top_image.url}/>
            <div className={'text'}>
            <h4>{formattedDate}</h4>
            <h3>{blog.data.page_title}</h3>
            <BlogPara>
            {RichText.render(blog.data.first_paragraph)}
            <a href={'/blog/' + blog.uid}>Read More</a>
            </BlogPara>
              </div>

          </BigBlock>

        :

        null
      }
        </div>
      );

    })


    const postMapSmall = posts.map(function(blog, index){
      const today = Date(blog.data.published_date);
      const formattedDate = format(today, 'PP');
      if(index !== 0){
      return(

        <LilBlock className={"buttons"}>
        <LilDate>{formattedDate}</LilDate>

        <img src ={blog.data.top_image.url}/>
        <h3>{blog.data.page_title}</h3>
        <a href={'/blog/' + blog.uid}>Read More</a>
        </LilBlock>
  );}else return null

    })

    console.log(posts);
    return (
      <div>
      <MainBlock>
      <div className={'bigHead'}><span/><h2>{blog.data.header}</h2><span/></div>
      <div className={'introText'}>{RichText.render(blog.data.paragraph)}</div>

      {postMap}

      </MainBlock>
          <SmallerHold>
      {postMapSmall}
  </SmallerHold>
  {indexSlices}
      </div>);
  } return (<h3>no page</h3>)}

export default BlogIndex;
