import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken} from '../prismic-configuration'
import {SliceZoneFoot} from './slicezone';


const Foot = styled.div`
width: 100%;
background-color: var(--maroon);
display: flex;
flex-wrap: wrap;



div{
  display: flex;
  width: 100%;
  margin: 20px 0;
}

ul{
  width: calc(100% / 6);
  margin-left: 30px;
  padding-left: 0;


}

h3{
  color: var(--cream);
  font-size: 18px;
  border-bottom: 2px solid;
  margin-bottom: 10px;
}

li, h4, p{
  color: var(--cream);
  font-size: 13px;
  line-height: 22px;
}

p{
  margin: 0;
}

h4{
  width: 100%;
  font-family: 'open sans';
  margin: 0 0 30px 30px;

}
@media(max-width: 600px){
  div{
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
      padding-top: 30px;
  }
  ul{
    width: 100%;
    margin-left: 0;
  }

  li{
    width: 100%;
  }

  h4{
    margin: 0 0 0 5%;
      padding-bottom: 30px;
  }
}
`

const Logos = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
a{
  margin-left: 30px;
}

img{
  height: 70px;
}

@media(max-width: 600px){
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  a{
    margin: 0;
  }
}
`




const Footer = () => {


  const [prismicData, setPrismicData] = useState({ navigation: null });

  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const navigation = await Client.getSingle('navigation');

        if (navigation) {
          setPrismicData({ navigation});
        } else {
          console.warn('Nav document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.navigation) {

    const nav = prismicData.navigation;



  return(
<Foot>


<SliceZoneFoot/>
<Logos>
<a href="https://www.unidosus.org/about/affiliates/" target="_blank"><img src="unidosusaffiliate_rgb_web.png"/></a>
<a href="https://www.oregonbusiness.com/100best/nonprofit/item/19395-100-best-nonprofits-to-work-for-in-oregon-2021-list" target="_blank"><img src="100_best_NP_logo_2021.png"/></a>

</Logos>
<h4>Copyright © 2022 Centro Cultural de Washington County</h4>
</Foot>
)}else return (null)
}

export default Footer;
