import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken} from '../prismic-configuration'
import {SliceZoneNav} from './slicezone';

export const Navigation = styled.div`
width: 100%;
border-bottom: 2px solid var(--maroon);
transition: 0.5s linear;
overflow: hidden;
position: fixed;
background-color: var(--cream);
z-index: 150;
display: flex;
justify-content: space-between;
// max-height: 90px;
max-height: 120px;
:hover{
  max-height: 400px;
}

ul{
  width: 100%;
  padding-bottom: 20px;
  li{
    font-family: 'Open Sans';
    color: var(--orange);
    margin: 9px 0;
    line-height: 1.3;
  }
}
`
export const Logo = styled.img`
//height: 50px;
height: 120px;
//margin-top: 4px;
`

const Spacer = styled.div`
// height: 90px;
height: 120px;
`

export const Hold = styled.div`
width: calc(100% - 40px);
display: flex;
margin: 0 auto;

h3{
  display: flex;
  justify-content: center;
  align-items: center;
}

ul{
padding-left: 0;
margin-left: 10px;
margin-right: 10px;
text-align: center;
font-weight: 600;

@media(max-width: 900px){
  font-size: 15px;
}
}

`

export const Translator = styled.div`
position: absolute;
top: -4px;
right: 0;
display: flex;
color: var(--maroon);
p{
  margin: 5px 5px 0 0;
  font-size: 12px;
  font-family: 'Open Sans';
  font-weight: 600;
  text-transform: uppercase;
}

.buttons{
  border: 2px solid;
  border-top: none;
  border-right: none;
}

.highlighted{
  background-color: var(--maroon);
  color: var(--cream);
}

button{
  border: none;
  margin: 0;
  padding: 4px 5px 2px;
  font-size: 12px;
 outline:none;
 color: var(--maroon);
 font-weight: 600;
 font-family: 'Open Sans';
  :hover{
    cursor: pointer;
  }

  :focus{
    border: none;
  }
}

`

export const SmIcons = styled.div`
width: 100%;
display: flex;
//margin-top: 30px;
margin-top: 40px;
justify-content: center;
flex-wrap: wrap;
@media(max-width: 800px){
  justify-content: center;
}
`

export const SmHold = styled.div`
// margin-top: 32px;
margin-top: 49px;
width: 100%;

justify-content: flex-end;

.alignRight{
  margin: 0 auto;
}
`


export const SmIcon = styled.img`
width: 24px;
height: 24px;
margin: 0 5px 10px;
@media(max-width: 800px){
  margin: 0 10px;
}
`

export const FlexNav = styled.div`
display: flex;
width: 100%;
align-items: flex-start;
`




const Nav = () => {


  const [prismicData, setPrismicData] = useState({ navigation: null });

  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const navigation = await Client.getSingle('navigation');

        if (navigation) {
          setPrismicData({ navigation});
        } else {
          console.warn('Nav document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.navigation) {

    const nav = prismicData.navigation;



  return(
<div>


  <SliceZoneNav/>


  <Spacer/>
</div>
)}else return (null)
}

export default Nav;
