import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState, Component} from 'react';
import {TopBlockHome, MainBlock, HalfBlocks, BigBlock, ThreeBlocks, VideoBlock} from './styles.js';
import Video from './video.js';
import Prismic from '@prismicio/client'
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken, linkResolver} from '../prismic-configuration'
import {SliceZoneHome} from './slicezone';
import Maps from './maps';
import ReactPlayer from 'react-player';
import {VideoContain, VideoText, Vid} from './video.js';
import { FacebookProvider, Page } from 'react-facebook';
import MailBlock from './mailChimpBlock';
import ScriptTag from 'react-script-tag';
import Popup from './popup';

export const TopBar = styled.div`
width: 35%;
min-height: 100%;
background-color: var(--yellow);
border-radius: 0 0 120px 0;
z-index: 30;
display:flex;
justify-content: center;
align-items: center;

@media(max-width: 850px){
  width: 66%;
  min-height: 0;
  height: fit-content;
  padding: 30px 0;
  position: absolute;
  top: 66%;
}

@media(max-width: 600px){
  width: 95%;
  top: 90%;
}
`

export const TopHold = styled.div`
width: 86%;

h1{
  font-size: 52px;
  color: var(--maroon);
  line-height: 48px;
  margin-bottom: 10px;

  @media(max-width: 1000px){
    font-size: 42px;
  }
  @media(max-width: 600px){
    font-size: 32px;
    line-height: 36px;

  }
}

h2{
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 24px;
  color: var(--clay);
  margin-bottom: 15px;

  @media(max-width: 1000px){
    font-size: 18px;
  }
}

@media(max-width: 600px){
  font-size: 16px
}
`

export const TopImg = styled.div`
width: calc(65% + 120px);
margin-left: -120px;

@media(max-width: 850px){
  width: 100%;
  margin-left: 0;
}
img{
  width: 100%;

  position: relative;
  object-fit: cover;
}

`



const Home = () => {

  const [prismicData, setPrismicData] = useState({ homePage: null });
  const english = localStorage.getItem('eng')


  // Get the homepage document from Prismic
  useEffect(() => {

    const fetchPrismicData = async () => {
      try {
        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getSingle('homepage', options);
        const defaultPage = await Client.getSingle('homepage', {lang: 'en-us'});

        let homeDoc;
        if(pageToggle === undefined){
          homeDoc = defaultPage;
        }else{
          homeDoc = pageToggle;
        }

        if (homeDoc) {
          setPrismicData({ homeDoc});
        } else {
          console.warn('Homepage document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.homeDoc) {

    const homeDoc = prismicData.homeDoc;




  const references = homeDoc.data.sidebar_buttons.map(function(reference, index) {
     return (
       <li key={index}>
          <a href={Link.url(reference.button, linkResolver)} className={'button2'} target={reference.button.target}><span/>{reference.button_text}</a>
       </li>
     );
   });

   let toppy;

   if(homeDoc.data.youtube.url){
toppy=
     <div className={'player-wrapper'}>
     <ReactPlayer
       className={'react-player'}
       url={homeDoc.data.youtube.url}
       width='100%'
       height='100%'
       playing= 'true'
       muted = 'true'
       volume = '0'
       config={{
         youtube: {
           playerVars: { modestbranding: 1 }
         },

       }}/>
       </div>
   }
   else if(homeDoc.data.video.url){
     toppy=
     <div className={'player-wrapper'}>
     <ReactPlayer
       className={'react-player'}
       url={homeDoc.data.video.url}
       width='100%'
       height='100%'
       playing= 'true'
       muted = 'true'
       volume = '0'
       config={{
         youtube: {
           playerVars: { modestbranding: 1 }
         },

       }}/>
       </div>
   }
   else if(homeDoc.data.image.url){
     toppy=
     <img src={homeDoc.data.image.url}/>
   }

  return(
    <div>
    <TopBlockHome>
      <TopBar>
        <TopHold>
        {RichText.render(homeDoc.data.sidebar)}

        {references}


        </TopHold>
      </TopBar>
      <TopImg>
    {toppy}
      </TopImg>
    </TopBlockHome>
    <MainBlock>
    <SliceZoneHome/>

    </MainBlock>
    <Maps/>
    </div>
  )}else return (null);
}

export default Home;
