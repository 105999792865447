import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState} from 'react';

const Pop = styled.div`
position: fixed;
z-index: 200;
bottom: 0;
right: 0;
background-color: var(--orange);
color: var(--cream);
width: 200px;
height: 200px;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;

border-radius: 200px 0 0 0;
transition: .5s linear;

@media(max-width: 500px){
  width: 100% !important;
  border-radius: 0px 100px 0 0;

  div{
    width: 100% !important;
    margin: 40px 0 0 0 !important;

    div{
      display: flex;
      justify-content: center;
      margin: 0 !important;
    }
  }
}

div{
  width: 70%;
  margin: 80px 0 0 40px;

  div{
    margin: 0 auto;
    width: fit-content;
  }



}


h3{
  text-align: center;
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  letter-spacing: 0.5px;

  @media(max-width: 500px){
    font-size: 30px;
  }
}
button{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin: 10px;
  outline: none;
  color: var(--cream);
  background: none;
  border: 2px solid;
  :hover{
    cursor: pointer;
    background-color: var(--yellow);

  }

  @media(max-width: 500px){
    height: 60px;
    width: 60px;
    margin: 25px 15px;
    border-radius: 30px;
  }
}


`





const makeSpanish = () =>{
  localStorage.setItem('eng', false);
  window.location.reload();
}
const hideBox = () =>{
  localStorage.setItem('eng', true);

}

const LangPop = () => {

  const checkLang = localStorage.getItem('eng');

  const [isHide, yeahHide] = useState(false);
  const addHide = () => {
    yeahHide(true)
    localStorage.setItem('eng', true);
  }

if(checkLang === null){
return(
  <Pop className={isHide ? 'hider' : ''}>
  <div>
    <h3>English or español?</h3>
    <div>
    <button onClick={addHide}>En</button>
    <button onClick={makeSpanish}>Es</button>
    </div>
</div>
  </Pop>
)} else return null;
}

export default LangPop;
