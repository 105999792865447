import styled, {createGlobalStyle} from 'styled-components';
import React, { useRef, useState, setState } from 'react';
import {} from './styles.js';

export const VideoContain = styled.div`
position: relative;
width: 100%;

margin: 30px 0;

display: flex;
align-items: center;
`

export const Vid = styled.div`
position: relative;
padding-bottom: 36.5625%;
width: 65%;
height: 0;
padding-top: 25;
transition: 0.25s linear;

@media(max-width: 600px){
  width: 100%;
  padding-bottom: 56.25%;
}
`

export const VideoText = styled.div`
width: 40%;
margin-right: -5%;
z-index: 30;
padding-left: 0;
margin-left: 0%;

height: fit-content;
border-radius: 0 0 60px 0;
transition: .25s linear;
background-color: var(--yellow);
div{
  padding: 30px;
}
h3{
  color: var(--maroon);
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 32px;
}

@media(max-width: 850px){
  width: 50%;
  margin-right: -10%;

  h3{
    font-size: 24px;
    line-height: 28px;
  }
}

@media(max-width: 600px){
  width: 100%;
  margin-right: -100%;
  margin-top: 56.25%;
  border-radius: 0;


}
`





const Video = () => {

  const [active] = useState(false);

  return(
  <VideoContain>
  <VideoText>
  <div>
    <h3>MEET LUCERO LOEZA AND HER JOURNEY TOWARDS PERSONAL TRIUMPH</h3>
    <a href="" className={'button2'}><span/>Learn More About Us</a>
    </div>
  </VideoText>
  <Vid>
  <iframe  style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }} src="https://www.youtube.com/embed/SYACA-0WYts" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></Vid>
  </VideoContain>
);
}

export default Video;
