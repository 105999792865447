import styled, {createGlobalStyle} from 'styled-components';
import React, { useRef, useState, setState } from 'react';

export const GlobalStyle = createGlobalStyle`
html{
   scroll-behavior: smooth;
}

body{
  margin: 0;
  background-image: url(/centroBG.svg);
  background-size: 100%;
  width: 100%;
}

.floatButton{
  width: 100%;
  display: flex;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Bebas Neue';
    margin: 0;
    font-weight:normal;
}

p{
  font-family: 'Open Sans'
}

a{
  text-decoration: none;
  color: inherit;
}

ul, li{
  text-decoration: none;
  list-style: none;
}

:root {
  --cream: #F4F1ED;
--maroon: #5B1718;
--clay: #79423F;
--orange: #EF6E2C;
--yellow: #F4B84D;
--green: #7E8E67;

}

.maroon{
  color: var(--maroon);
}


.topHome{
    font-size: 52px;
    color: var(--maroon);
    line-height: 48px;
    margin-bottom: 10px;
}

.clear{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.subHome{
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 24px;
  color: var(--clay);
  margin-bottom: 15px;
}

.Nav1{
//  margin-top: 13px;
margin-top: 30px;
//  margin-bottom: 32px;
margin-bottom: 42px;
  font-family: 'Open Sans';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  height: 40px;
  text-align: center;
  color: var(--maroon);

  @media(max-width: 900px){
    font-size: 15px;
  }

  :hover{
    color: var(--orange);
  }
}

.navButton{
  margin-top: 30px;
  margin-right: 20px;
  margin-left: 10px;
}

.hideText{
  margin-left: -41%;
  margin-right: 1%;

  @media(max-width: 600px){
    margin-left: -90%;
  }
}

.vidExpand{
  width: 100% !important;
  padding-bottom: 56.25% !important;
}

.image-gallery{
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  img{
    object-fit: cover !important;
  }
}

.image-gallery-thumbnails .image-gallery-thumbnails-container{
    text-align: left;
}

.image-gallery-slides{
  border: 2px solid var(--maroon);
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:hover, .image-gallery-thumbnail:focus{
  border: none;
}

.image-gallery-thumbnail{
  padding: 0;
  outline: none;
  border: none;
  opacity: 0.5;
  margin-right: 10px;
}

.image-gallery-thumbnail:hover{
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.5s;
}

.image-gallery-thumbnail.active{

  padding: 0;
  opacity: 1;
  transition: opacity 0.5s;
}



.image-gallery-thumbnail + .image-gallery-thumbnail{
  margin-left: 0;
}


.buttonBlog{
  font-family: 'Bebas Neue';
  color: var(--cream) !important;
  font-weight: normal !important;
  background-color: var(--orange);
  font-size: 26px;
  padding: 3px 30px 0;
  height: 36px;
  border-radius: 18px;
  border: none;
  transition: .5s ease-in;
  margin: 30px auto ;
  :hover{
    background-color: var(--yellow);
  }

  @media(max-width: 600px){
    margin: 10px auto;
  }
}


.kindfulButton{
  font-family: 'Bebas Neue' !important;
  font-size: 16px !important;
padding: 10px 30px !important;
text-decoration: none !important;
text-align: center !important;
display: inline-block !important;
border: none !important;
box-shadow: none !important;
color: rgb(255, 255, 255) !important;
background: rgb(240, 110, 45) !important;
border-radius: 4px !important;
cursor: pointer;
  :hover{
    background-color: var(--yellow) !important;
  }
}

.button1{
  font-family: 'Bebas Neue';
  color: var(--cream);
  background-color: var(--orange);
  font-size: 26px;
  padding: 3px 30px 0;
  height: 36px;
  border-radius: 18px;
  border: none;
  transition: .5s ease-in;
  margin: 30px auto ;
  :hover{
    background-color: var(--yellow);
  }

  @media(max-width: 600px){
    margin: 10px auto;
  }
}
.full{
  width: 100%;
}

.spacee{
  :after{
    content: "";
    width: 32%;
  }
}

.button2{
  color: var(--orange);
  font-family: 'Bebas Neue';
  font-size: 24px;
  line-height: 0;
  height: 30px;
  margin: 0 40px 0 30px;
  border: none;
  position: relative;
  transition: .5s ease-in;



  :before{
    padding: 15px 10px;
    background-color: var(--orange);
    display: inline-block;
    content: ">";
    border-radius: 15px;
    color: var(--cream);
    transition: .5s ease-in;
    width: 10px;
    position: absolute;
    z-index: -1;
    left: -35px;
    top: 0px;

  }

  :hover{
    color: var(--cream);
    :before{
      transition: .5s ease-in;

    }
    span{
    width: calc(100% + 55px);
    }


  }



  span{
    height: 30px;
top: 0px;
    z-index: -1;
    left: -35px;
    background-color: var(--orange);
    width: 30px;
    border-radius: 15px;
    position: absolute;
    transition: .5s ease-in;


      :before{
        content: ">";
        top: 16px;
        position: absolute;
        left: 11px;
        color: var(--cream);
        font-family: "Bebas Neue"
      }
  }
  @media(max-width: 800px){
    font-size: 18px;
      :before{
        top: -4px;
      }
      span{
        top: -4px;
      }
  }
}

body{
  background-color: var(--cream);
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  span{
    left:0;
    top:0;
    height:100% !important;
    width:100% !important;
    position:absolute;

    iframe{
      height:100% !important;
      width:100% !important;

    }
  }

}

.quickjump{
  display: flex;
  color: var(--maroon);
  width: 100%;
  margin-bottom: 30px;
  margin-top: 5px;
  flex-wrap: wrap;
  h3{
    margin-right: 10px;
  }


}

.introText{
  a{
    font-weight: normal;
    margin-top: 10px !important;
  }
  h3{
    font-family: 'open sans';
    font-weight: 600;
    width: 85%;
    max-width: 1200px;
    color: var(--maroon);
    margin-bottom: 10px;
    font-size: 24px;
  }

  @media(max-width: 600px){
    h3{
    font-size: 18px;
    width: 100%;
  }}
}

.qj {
    color: var(--orange);
    margin-right: 0 !important;

    :hover{
      cursor: pointer;
      color: var(--yellow);
    }
+.qj{
    :before{
      content: "|";
      padding: 0 10px;
      color: var(--orange);
    }
  }
}

.halfBlock{
  width: 49%;
  position: relative;
  color: var(--maroon);
  height: fit-content;

  @media(max-width: 600px){
    width: 100%;
    height:fit-content;
  }
  h3{
    font-size: 36px;


  }
  p{
    margin: 0 0 20px 0;
    @media(max-width: 700px){
    }
  }

  img{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 16px;
    height: 30vw;
    object-fit: cover;

  }
}

.teamHide{
  max-height: 0;
  transform-origin: top;
  transition: 0.26s ease;
}

.buttonSpin{
  transform: rotate(90deg) !important;
}

.subheadtext{

    color: var(--clay);
    font-size: 30px;
    margin: 0 0 10px;
    text-align: center;
    width: 100%;

}

.bigHead{
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--maroon);
  margin: 60px 0;
  align-items: center;
  h2{
    font-size: 60px;
    margin: 0 20px;
    text-align: center;
  }



.clicked{
  width: 100%;
  margin-left: 0;
  min-height: 150%;
}

  span{
    width: 110px;
    height: 18px;
    background-image: url('/squiggle.svg');
  }

  @media(max-width: 800px){
    margin: 20px 0;
    h2{
      font-size: 36px;
    }

    span{
      width: 0;
      height: 9px;
    }
  }
}



.blogHead{
  width: 100%;
  display: flex;
  justify-content: left;
  color: var(--maroon);
  margin: 30px 0 0;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font-size: 60px;
    margin: 0 0;
    line-height: 60px;
  }

  h4{
    width: 100%;
    color: var(--yellow);
    font-size: 20px;
    margin: 0;

  }

.clicked{
  width: 100%;
  margin-left: 0;
  min-height: 150%;
}

  span{
    width: 110px;
    height: 18px;
    background-image: url('/squiggle.svg');
  }
}

.blogpadLeft{
  margin-right: 10px;
}

.blogpadRight{
  margin-left: 10px;
}

.buttons{
  a{
    color: var(--orange);
    font-family: 'Bebas Neue';
    font-size: 24px;
    margin: 0px 40px 20px 30px;
    border: none;
    line-height: 30px;
    position: relative;
    transition: .5s ease-in;
    float: left;

    strong{
      font-weight: normal;
    }

    :before{
      padding: 15px 10px;
      background-color: var(--orange);
      display: inline-block;
      content: ">";
      border-radius: 15px;
      color: var(--cream);
      transition: .5s ease-in;
      width: 10px;
      line-height: 0;
      position: absolute;
      z-index: -1;
      left: -35px;



    }

    :hover{
      color: var(--cream);
      :before{
        width: calc(100% + 30px);
        transition: .5s ease-in;
      height: inherit;      }

    }



    @media(max-width: 800px){
      font-size: 18px;
        :before{
          top: -2px;
        }
        span{
          top: -2px;
        }
    }
  }
}

.buttons2{

  a{
    color: var(--orange);
    font-weight: 600;
    transition: .25s ease-in;
    :hover{
      color: var(--yellow);
    }
  }
h6{
  a{
    color: var(--orange);
    font-family: 'Bebas Neue';
    font-size: 24px;
    margin: 0px 40px 20px 30px;
    border: none;
    line-height: 30px;
    position: relative;
    transition: .5s ease-in;
    float: left;
    font-weight: normal;
    strong{
      font-weight: normal;
    }

    :before{
      padding: 15px 10px;
      background-color: var(--orange);
      display: inline-block;
      content: ">";
      border-radius: 15px;
      color: var(--cream);
      transition: .5s ease-in;
      width: 10px;
      line-height: 0;
      position: absolute;
      z-index: -1;
      left: -35px;



    }

    :hover{
      color: var(--cream);
      :before{
        width: calc(100% + 30px);
        transition: .5s ease-in;
      height: inherit;      }

    }



    @media(max-width: 800px){
      font-size: 18px;
        :before{
          top: -2px;
        }
        span{
          top: -2px;
        }
    }
  }
  }
}

.slide{
  background: none !important;
}

.control-arrow{
  background: none !important;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity: 1;
}

.control-next.control-arrow{
      right: -5px !important;
}

.control-prev.control-arrow{
      left: -5px !important;
}

.carousel .control-next.control-arrow:before {
    /* border-left: 8px solid #fff; */
    background: var(--orange);
    /* opacity: 1 !important; */
    padding: 10px 10px;
    line-height: 0;
    content: ">";
    border-radius: 20px;
    border-left: none;

  }

  .carousel .control-prev.control-arrow:before{
    background: var(--orange);
    /* opacity: 1 !important; */
    padding: 10px 10px;
    line-height: 0;
    content: "<";
    border-radius: 20px;
    border-right: none;
  }

  .control-dots, .carousel-status, .thumbs-wrapper{
    display: none;
  }

  .menuHide{
    max-height: 1000px !important;
  }

  .hamburger-react{
  //  margin-top: 24px;
    margin-top: 14px;
    color: var(--maroon);
  }

  .hider{
    bottom: -200px;
    right: -200px;
  }

  .Rust{
    background-color: #79423F;
    background-image: url('eventbg.svg');
  }

  .Cream{
    background-color: #E5DBCF;
    background-image: url('eventbg_cream.svg');
  }
`



export const EventHold = styled.div`
`

export const Event = styled.div`
width: 100%;
display: flex;
background-size: 100px;
position: relative;
color: var(--maroon);
margin: 30px 0;


div{
background-color: var(--cream);
margin: 30px;
padding: 30px;
position: relative;
z-index: 1;
width: 100%;

}

.hold{
  display: flex;
  align-items: center;
  @media(max-width: 800px){
    flex-wrap: wrap;
  }
  div{
    display: block;
    margin: 0;
    padding: 0;
  }
}

.eventImg{
  position: relative;
  width: 50%;
  object-fit: contain;
  margin-right: 30px;
  @media(max-width: 800px){
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.cactus{
  position: absolute;
  bottom: -10px;
  right: -10px;
  z-index: 1;
  width: 80px;
}

h2{
  font-size: 42px;
  line-height: 44px;
  width: 100%;
  margin-bottom: 10px;
}

p{
  width: 100%;
  margin: 10px 0;
  max-width: 700px;
a{
  font-weight: normal;
}
}

h4{
  font-family: 'Open Sans';
  font-weight: 600;
  margin: 0;
  width: 100%;
  a{
    font-weight: normal;
  }
}

@media(max-width: 600px){
  h2{
    font-size: 28px;
    line-height: 30px;
    width: 100%;
  }

  p{
    width: 100%;
  }

  div{
    margin: 10px;
    padding: 15px;
  }

  img{
    width: 50px;
  }


}

`

export const Main = styled.div`
width: 100%;
margin-top: 40px;
`

export const TopBlock=styled.div`
width: 100%;
display: flex;
position: relative;
border-bottom: 2px solid var(--maroon);

@media(max-width: 500px){
  display: block;
}
`

export const TopBlockHome=styled(TopBlock)`
@media(max-width: 850px){
  margin-bottom: 220px;
}
`


export const HalfBlocks=styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin: 30px 0;

@media(max-width: 600px){
  flex-wrap: wrap;
}


`

export const VideoBlock=styled.div`

`

export const BigBlock =styled.div`
display: flex;
align-items: center;
justify-content: space-between;
color: var(--maroon);
margin: 30px 0;
img{
  width: 49%
}
.text{
  width: 49%;
}

h4{
  color: var(--yellow);
}

h3{
  font-size: 42px;
}

p{
  margin: 10px 0 20px;
}

@media(max-width: 600px){
  flex-wrap: wrap;
  img{
    width: 100%;
  }
  .text{
    width: 100%;
    margin-top: 15px;
  }
}

`

export const Overlap = styled.div`
width: 100%;
display: flex;
align-items: center;
color: var(--maroon);
margin: 30px 0;
font-weight: normal;
strong{
  font-weight: 600;
  a{
  font-weight: normal;
}
}
img {
  width: 66%;
  object-fit: contain;
  margin-right: calc(-10% - 60px);
  border-radius: 0 0 80px 0;
    }

div{
  width: 50%;
  background-color: white;
  height: max-content;
  padding: 30px;
  z-index: 1;

  p{
    margin: 0 0 10px 0;
  }
}

@media(max-width: 600px){
  flex-wrap: wrap;
  div{
    width: 100%;
    padding: 10px;
  }
  img{
    border-radius: 0;
    width:100%;
  }
}

`

export const Paragraph = styled.div`
width: 80%;
max-width: 900px;
color: var(--maroon);
margin: 30px auto ;
h3{
  font-size: 36px;
}

p{
  margin: 10px 0;
}

ul{
  padding: 10px 0;
  margin-block-start: 0;
padding-inline-start: 0;
border-top: 2px solid;
border-bottom: 2px solid;
}

li{
  margin: 0 0 10px;
  color: var(--clay);
  strong{
    color: var(--maroon);
    text-transform: uppercase;
  }
}

@media(max-width: 600px){
  width: 100%;
}
`

export const BlogParagraph = styled(Paragraph)`
  width: 100%;
  margin-bottom: 15px;
  h3{
    margin: 15px 0 0;
  }

  p{
    margin: 0 0 10px;
  }
`

export const BlogParagraph2 = styled(BlogParagraph)`
margin: 0 0 0px;

p{
  margin: 0 0 30px;
}

`






export const MainBlock=styled.div`
width: 96%;
display: flex;
margin: 30px auto;
justify-content: space-between;
flex-wrap: wrap;
p{
  font-size: 16px;
  strong{
    font-weight: 600;
  }
}

strong{
  font-weight: normal;
}

@media(max-width: 600px){
  width: 90%;
}

&.blog{
  max-width: 900px;
  margin: 30px 0 0 2% ;
}

`

export const Report = styled.div`
display: flex;
align-items: center;
color: var(--maroon);
margin: 30px 0;
img{
  width: 33%;
  margin-right: 20px;
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

h3{
  font-size: 36px;
}

p{
  margin: 0 0 10px;
  max-width: 700px;
}

@media(max-width: 600px){
  flex-wrap: wrap;
  img{
    width: 100%;
    margin-bottom: 10px;
  }
}
`

export const ThreeBlocks=styled.div`
width: 100%;
margin: 45px 0;
display: flex;
flex-wrap: wrap;
color: var(--maroon);
justify-content: space-between;

.threeCard{
background-color: #fff;
border-radius: 16px;
width: 32%;
margin-top: 15px;
padding-bottom: 20px;

overflow: hidden;
.imghold{
  width: 100%;
  position: relative;
  padding-bottom: 66%;
  overflow: hidden;
  margin-bottom: 10px;
}

.texthold{
  width: 90%;
  margin: 0 auto;
}

  img{
    position: absolute;
    top:0;
    left:0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  h4{
    font-size: 18px;
  }

  p{
    margin: 5px 0 20px;
    font-size: 16px;
    height: 68px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  a{
    z-index: 4;
  }

  @media(max-width: 600px){
    width: 90%;
    margin: 0 auto;
  }
}
`
