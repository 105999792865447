import React, {Fragment} from 'react'
import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import Home from './pages/homepage.js';
import Nav from './pages/nav.js'
import NavSmall from './pages/navSmall.js';
import {GlobalStyle} from './pages/styles.js';
import Footer from './pages/footer.js';
import Program from './pages/program';
import BlogIndex from './pages/blogIndex.js';
import Team from './pages/team.js';
import BlogPost from './pages/blogPost.js';
import LangPop from './pages/language.js';
import Media from 'react-media';
import ReactGA from 'react-ga';
import RouteChangeTracker from './routeChangeTracker';
import Preview from './Preview';
import { apiEndpoint } from './prismic-configuration';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';

const TRACKING_ID = "UA-194274647-1";
ReactGA.initialize(TRACKING_ID);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = (props) =>{
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];
  return (

    <BrowserRouter history={history}>
    <Helmet>
    <title>Centro Cultural</title>
    <meta name="description" content="A home for Latino cultures, Centro serves the needs of our diverse community by promoting personal growth and empowerment."/>
    </Helmet>
    <RouteChangeTracker/>
    <GlobalStyle/>
    <Media query="(min-width: 801px)" render={() => <Nav/>}/>
    <Media query="(max-width: 800px)" render={() => <NavSmall/>}/>
    <Switch>
    <Route exact path="/" component ={Home}/>
    <Route exact path="/blog" component={BlogIndex}/>
    <Route exact path="/:uid" component={Program} />
    <Route exact path="/team/:uid" component={Team}/>
    <Route exact path="/blog/:uid" component={BlogPost} />
    </Switch>
    <Footer/>
    <LangPop/>
  </BrowserRouter>

);
}

export default App;
