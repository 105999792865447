
import React, {useEffect, useState} from 'react';
import { Client, apiEndpoint, accessToken, linkResolver} from '../prismic-configuration'
import TwoBlocks from './slices/twoBlocks';
import { Date, Link, RichText } from 'prismic-reactjs'
import {HalfBlocks, BigBlock, ThreeBlocks, Event, EventHold} from './styles.js';
import {VideoContain, VideoText, Vid} from './video.js';
import {Navigation, Logo, Hold, Translator, SmIcon, SmIcons, SmHold, FlexNav} from './nav.js';
import {NavigationSmall, LogoSmall, HoldSmall, Chunk, HoldSmallSub, TranslatorSmall} from './navSmall.js';
import ReactPlayer from 'react-player'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Media from 'react-media';
import {Spin as Hamburger} from 'hamburger-react';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import {MailchimpHold, McInfo, Mailchimp, MailchimpText, Squares, Squares2} from './mailChimpBlock.js';
import ScriptTag from 'react-script-tag';
import styled, {createGlobalStyle} from 'styled-components';
import {FacebookHold, FbInfo, Facebook, FacebookText, FacebookClear} from './facebookBlock.js';
import Iframe from 'react-iframe'
const url = "//centrocultural.us7.list-manage.com/subscribe/post?u=fa05f03020ac4167d6f24237b&amp;id=d976bfb7e6";


/**
 * Prismic Slice Zone component
 */
export const SliceZoneHome = () => {

  const [prismicData, setPrismicData] = useState({ homePage: null });
  const english = localStorage.getItem('eng')
  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getSingle('homepage', options);
        const defaultPage = await Client.getSingle('homepage', {lang: 'en-us'});

        let homeDoc;
        if(pageToggle === undefined){
          homeDoc = defaultPage;
        }else{
          homeDoc = pageToggle;
        }

        if (homeDoc) {
          setPrismicData({ homeDoc});
        } else {
          console.warn('Homepage document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.homeDoc) {
    const homeDoc = prismicData.homeDoc;

    const homeSlices = homeDoc.data.body.map((slice, index) => {
  // Render the right markup for the given slice type

  // Text Slice
  if (slice.slice_type === '2_blocks') {
    console.log(slice.primary.sms_text_2);
    console.log(slice.primary.sms_number_2);

    let smsCheck;
    let smsCheck2;

    if(slice.primary.sms_number_2 === null || ''){
      smsCheck2 = 'hide'
    }else{
      smsCheck2 = 'show'
    }

    if(slice.primary.sms_number === null || ''){
      smsCheck = 'hide'
    }else{
      smsCheck = 'show'
    }

    console.log(smsCheck2);
    console.log(smsCheck);
    return (
      <HalfBlocks className={'buttons2'} ref={(section) => { slice.primary.nav_text = section; }}>
        <div className={'halfBlock'}>
        <img src={slice.primary.image_1.url}></img>
        <h3>{RichText.asText(slice.primary.header_1)}</h3>
        {RichText.render(slice.primary.text_1, linkResolver)}
        {smsCheck === 'show' && <h6><a href={`sms://` + slice.primary.sms_number}>{slice.primary.sms_text}</a></h6>}
      </div>
        <div className={'halfBlock'}>
        <img src={slice.primary.image_2.url}></img>
        <h3>{RichText.asText(slice.primary.header_2)}</h3>
        {RichText.render(slice.primary.text_3, linkResolver)}
        {smsCheck2 === 'show'  && <h6><a href={`sms://` + slice.primary.sms_number_2}>{slice.primary.sms_text_2}</a></h6>}
      </div>
      </HalfBlocks>
);

  }

  // Big Head Slice
else if(slice.slice_type === 'section_title'){
  return(
    <div className={'bigHead'}><span/><h2>{RichText.asText(slice.primary.header)}</h2><span/></div>
  )

}

//Feature block
else if(slice.slice_type === 'feature_block'){
  return(
    <BigBlock className={'buttons'}>
      <img src={slice.primary.image.url}/>
      <div className={'text'}>
      <h4>{RichText.asText(slice.primary.label)}</h4>
      <h3>{RichText.asText(slice.primary.header)}</h3>
    {RichText.render(slice.primary.text, linkResolver)}
        </div>
    </BigBlock>
  )
}

//small 2_blocks
  else if (slice.slice_type === '3_blocks'){
    const block = slice.items.map((block, blockIndex) => (
      <div className={'threeCard'} key={blockIndex}>
      <div className={'imghold'}><img src={block.image.url} /></div>
      <div className={'texthold'}>
        <h4>{block.header}</h4>
        {RichText.render(block.text)}

        <a href={Link.url(block.link, linkResolver)} className={'button2'} target={block.link.target}><span/>{block.link_text}</a>

      </div></div>
       ));

    return(
<div>
        <Media query="(min-width: 601px)" render={() =>
    <ThreeBlocks>
    <h3 className={'subheadtext'}>{slice.primary.section_head}</h3>
    {block}
          <div className={'clear'}><a href={Link.url(slice.primary.link, linkResolver)} className={'button1'} target={slice.primary.link.target}>{slice.primary.link_text}</a></div>
      </ThreeBlocks>
       }/>



               <Media query="(max-width: 600px)" render={() =>
           <ThreeBlocks>
           <h3 className={'subheadtext'}>{slice.primary.section_head}</h3>
           <Carousel>
           {block}
           </Carousel>
                 <div className={'clear'}><a href={Link.url(slice.primary.link, linkResolver)} className={'button1'} target={slice.primary.link.target}>{slice.primary.link_text}</a></div>
             </ThreeBlocks>
              }/>

</div>
    )
  }

  else if (slice.slice_type === 'youtube'){

    const VidToggle = () => {
    const [show, toggleShow] = React.useState(false);

    return(
      <VideoContain>
      <VideoText className={show && 'hideText'}>
      <div className={'buttons'}>
        <h3>{RichText.asText(slice.primary.header)}</h3>
        {RichText.render(slice.primary.links, linkResolver)}
        </div>
      </VideoText>
      <Vid className={show && 'vidExpand'}>
      <ReactPlayer
        className={'react-player'}
        url={slice.primary.video.url}
        width='100%'
        height='100%'
        onPlay={() => toggleShow(!show)}
        onPause={() => toggleShow(!show)}
        config={{
          youtube: {
            playerVars: { modestbranding: 1 }
          }

        }}/>
      </Vid>
      </VideoContain>
    )}

    return(<VidToggle/>)
  }

  else if (slice.slice_type === 'event'){
    const bottomGraphic = slice.primary.bottom_graphic;

    return(
      <EventHold>
      <h3 className={'subheadtext'}>{slice.primary.section_head}</h3>

      <Event className={`buttons ${slice.primary.border}`}>

        <div className={'hold'}>
        <img className={'eventImg'} src={slice.primary.image1.url}/>
        <div>
        {RichText.render(slice.primary.text)}
        </div>
        </div>
        {bottomGraphic === "Cactus" ? <img className={'cactus'} src='cactus.svg'/> : null}
      </Event>
      </EventHold>
    )
  }
  else if (slice.slice_type === 'mailchimp'){
    const CustomForm = ({ status, message, onValidated }) => {
      let email;
      let fname;
      let lname;
      const submit = () =>
        email &&
        fname &&
        lname &&
        email.value.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email.value,
          FNAME: fname.value,
          LNAME: lname.value
        });

      return (
        <MailchimpHold>
        <Squares/>
        <McInfo>
        <h3>{slice.primary.header}</h3>
        {RichText.render(slice.primary.paragraph)}
        </McInfo>

        <Mailchimp>
        <MailchimpText>
            {status === "sending" && <p>{slice.primary.sending}</p>}
            {status === "error" && (
              <p>{slice.primary.error}</p>
            )}
            {status === "success" && (
              <p>{slice.primary.success}</p>
            )}
              {status === null && <p>{slice.primary.default_text}</p>}
              </MailchimpText>
              <div>
          <input
            ref={node => (fname = node)}
            type="text"
            placeholder="First Name"
          />
          <input
            ref={node => (lname = node)}
            type="text"
            placeholder="Last Name"
          />
          </div>
          <input
            ref={node => (email = node)}
            type="email"
            placeholder="Your email"
          />
          <button onClick={submit}>
            Submit
          </button>
          </Mailchimp>
          <Squares2/>
        </MailchimpHold>
      );
    };

    return(<MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => (
      <CustomForm
        status={status}
        message={message}
        onValidated={formData => subscribe(formData)}
      />
    )}
    />)

  }

  else if (slice.slice_type === 'facebook_embed'){
    return(
      <FacebookClear className="buttons2">
      <FacebookHold>
      <Squares/>
      <FbInfo>
      <h3>{slice.primary.header}</h3>

      {RichText.render(slice.primary.paragraph)}
      </FbInfo>

      <Facebook>
      {slice.primary.page_toggle ?
        <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FProsperidadCentro%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=833893947539216" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>
        :
        <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcentrocultural.org&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=833893947539216" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>

      }

        </Facebook>
        <Squares2/>
      </FacebookHold>
      </FacebookClear>
    )
  }

  else if(slice.slice_type === 'donate_button'){
    const KindHold = styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
    `

      return(
        <KindHold>
        <a className={'kindfulButton'} id={`kindful-donate-btn-` + slice.primary.id}>Donate</a>
  <ScriptTag src={`https://centrocultural.kindful.com/embeds/` + slice.primary.id + `/init.js`} data-embed-id={slice.primary.id} data-lookup-type='jquery-selector' data-lookup-value={`#kindful-donate-btn-` + slice.primary.id}></ScriptTag>
      </KindHold>
      ) }

    else {
    return null;
  }
});

  return(
    <>
    {homeSlices}
    </>
  );

}else return(null)}

export const SliceZoneNav = () => {
  const [prismicData, setPrismicData] = useState({ navigation: null });
  const english = localStorage.getItem('eng')

  // Get the homepage document from Prismic
  useEffect(() => {

    const fetchPrismicData = async () => {
      try {
        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getSingle('navigation', options);
        const defaultPage = await Client.getSingle('navigation', {lang: 'en-us'});

        let navigation;
        if(pageToggle === undefined){
          navigation = defaultPage;
        }else{
          navigation = pageToggle;
        }

        if (navigation) {
          setPrismicData({ navigation});
        } else {
          console.warn('Nav document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.navigation) {

    const nav = prismicData.navigation;
    const menu = nav.data.body.map((slice, index) => {
      if(slice.slice_type=== 'menu'){

        const submenu = slice.items.map((block, blockIndex) => (
          <a href={Link.url(block.link, linkResolver)} key={index} target={block.link.target}><li >{block.subhead}</li></a>
        ));
        return(

          <ul>
          <a href={Link.url(slice.primary.link, linkResolver)} target={slice.primary.link.target}><h3 className={'Nav1'}>{slice.primary.header}</h3></a>
          {submenu}
          </ul>

        );
      }else return null
    });
    const changeLang = () =>{
      const checkLang = localStorage.getItem('eng');
      if(checkLang === 'true'){
        localStorage.setItem('eng', false);
        window.location.reload();
      }else {
      localStorage.setItem('eng', true);
      window.location.reload();
    }
    }

    const makeEnglish = () =>{
      localStorage.setItem('eng', true);
      window.location.reload();
    }

    const makeSpanish = () =>{
      localStorage.setItem('eng', false);
      window.location.reload();
    }

    const langClass = localStorage.getItem('eng');
    console.log(langClass);
    return(
      <Navigation>
      <Translator>
      <p>Translate this Page</p>
      <div className={'buttons'}>
      <button onClick={makeEnglish} className={langClass === 'false' ? '' : 'highlighted'}>EN</button>
      <button onClick={makeSpanish} className={langClass === 'false' ? 'highlighted' : ''}>ES</button>

      </div>
      </Translator>
      <Hold>
      <a href="/"><Logo src='/CentroFiftyYear.png'/></a>
      {menu}
      <SmHold>
      <FlexNav><a href="https://centrocultural.kindful.com" target="_blank"  className={'button1 navButton alignRight'}>Donate
      </a></FlexNav>
      <SmIcons>
      <a href="https://twitter.com/centro_washco?lang=en" target="_blank"><SmIcon src="/TwitterLogo.svg"/></a>
      <a href="https://www.facebook.com/CentroCultural.org" target="_blank"><SmIcon src="/facebook_Icon.svg"/></a>
      <a href="https://www.instagram.com/centro_oregon/" target="_blank"><SmIcon src="/IgIgon.svg"/></a>
      <a href="https://www.youtube.com/channel/UCGCdaxc81wia6tLovrWnljA/" target="_blank"><SmIcon src="/youtubeLogo.svg"/></a>
      <a href="https://www.linkedin.com/company/centro-cultural-de-washington-county/" target="_blank"><SmIcon src="/linkedinLogo.svg"/></a>
      </SmIcons>

      </SmHold>
      </Hold>
      </Navigation>
    )
}else return null
}


export const SliceZoneNavSmall = () => {
  const [prismicData, setPrismicData] = useState({ navigation: null });
  const english = localStorage.getItem('eng')

  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getSingle('navigation', options);
        const defaultPage = await Client.getSingle('navigation', {lang: 'en-us'});

        let navigation;
        if(pageToggle === undefined){
          navigation = defaultPage;
        }else{
          navigation = pageToggle;
        }

        if (navigation) {
          setPrismicData({ navigation});
        } else {
          console.warn('Nav document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.navigation) {

    const nav = prismicData.navigation;
    const menu = nav.data.body.map((slice, index) => {
      if(slice.slice_type=== 'menu'){

        const submenu = slice.items.map((block, blockIndex) => (
          <a href={Link.url(block.link, linkResolver)} key={index} target={block.link.target}><li >{block.subhead}</li></a>
        ));
        return(
          <ul>
          <a href={Link.url(slice.primary.link, linkResolver)} target={slice.primary.link.target}><h3 className={'Nav1'}>{slice.primary.header}</h3></a>
          {submenu}
          </ul>
        );
      }else return null
    });
    const Toggle = () => {
      const [show, toggleShow] = React.useState(false);
      const makeEnglish = () =>{
        localStorage.setItem('eng', true);
        window.location.reload();
      }

      const makeSpanish = () =>{
        localStorage.setItem('eng', false);
        window.location.reload();
      }

      const langClass = localStorage.getItem('eng');
      return(
        <NavigationSmall>

        <HoldSmall>
        <a href="/"><Logo src='/CentroFiftyYear.png'/></a>
        <Hamburger toggled={show} toggle={toggleShow} duration={0.8}/>
        </HoldSmall>
        <HoldSmallSub className = {show && 'menuHide'}>

        <TranslatorSmall>
        <p>Translate this Page:</p>
        <div className={'buttons'}>
        <button onClick={makeEnglish} className={langClass === 'true' ? 'highlighted' : ''}>EN</button>
        <button onClick={makeSpanish} className={langClass === 'true' ? '' : 'highlighted'}>ES</button>

        </div>
        </TranslatorSmall>

        {menu}
        <SmIcons>
        <a href="https://twitter.com/centro_washco?lang=en" target="_blank"><SmIcon src="/TwitterLogo.svg"/></a>
        <a href="https://www.facebook.com/CentroCultural.org" target="_blank"><SmIcon src="/facebook_Icon.svg"/></a>
        <a href="https://www.instagram.com/centro_oregon/" target="_blank"><SmIcon src="/IgIgon.svg"/></a>
        <a href="https://www.youtube.com/channel/UCGCdaxc81wia6tLovrWnljA/" target="_blank"><SmIcon src="/youtubeLogo.svg"/></a>
        <a href="https://www.linkedin.com/company/centro-cultural-de-washington-county/" target="_blank"><SmIcon src="/linkedinLogo.svg"/></a>
        </SmIcons>
        <a href="https://centrocultural.kindful.com"  target="_blank" className={'button1 navButton'}>Donate</a>
        <Chunk/>

        </HoldSmallSub>

        </NavigationSmall>)
    }
    return(
      <Toggle/>
    )
}else return null
}

export const SliceZoneFoot = () => {
  const [prismicData, setPrismicData] = useState({ navigation: null });
  const english = localStorage.getItem('eng')
  // Get the homepage document from Prismic
  useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const navigation = await Client.getSingle('navigation');

        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getSingle('homepage', options);
        const defaultPage = await Client.getSingle('homepage', {lang: 'en-us'});

        let homeDoc;
        if(pageToggle === undefined){
          homeDoc = defaultPage;
        }else{
          homeDoc = pageToggle;
        }


        if (navigation) {
          setPrismicData({ navigation, homeDoc});
        } else {
          console.warn('Nav document was not found. Make sure it exists in your Prismic repository.');
          }
      } catch (error) {
        console.error(error);

      }
    }

    fetchPrismicData();
  }, []);

  if (prismicData.navigation) {

    const nav = prismicData.navigation;
    const home = prismicData.homeDoc;

    const menu = nav.data.body.map((slice, index) => {
      if(slice.slice_type=== 'menu'){

        const submenu = slice.items.map((block, blockIndex) => (
          <a href={Link.url(block.link, linkResolver)} key={index}><li >{block.subhead}</li></a>
        ));
        return(

          <ul>
          <a href={Link.url(slice.primary.link, linkResolver)}><h3>{slice.primary.header}</h3></a>
          {submenu}
          </ul>

        );
      }else return null
    });
    const Spacer = styled.div`
    margin: 10px 0 0 !important;
    width: 0;
    height: 0;
    `
    const visit = home.data.visit_us.map((addy, index) => (
      <>
      <li><strong>{addy.location_name}</strong></li>
      {RichText.render(addy.address)}
      <Spacer/>
      </>
    ))


    return(

      <div>
      {menu}
      <ul>
      <a><h3>Contact Us</h3></a>
      {visit}

      </ul>
      </div>
    )
}else return null
}
