import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState, setState, useRef} from 'react';
import {
  TopBlock, MainBlock, HalfBlocks,
  BigBlock, ThreeBlocks, VideoBlock,
  GlobalStyle, Overlap, Paragraph, BlogParagraph, BlogParagraph2,
  Report} from './styles.js';
import {TopHold, TopBar, TopImg} from './homepage';
import Video from './video.js';
import Prismic from '@prismicio/client'
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken, linkResolver} from '../prismic-configuration';
import {VideoContain, VideoText, Vid} from './video.js';
import ReactPlayer from 'react-player'
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import ScriptTag from 'react-script-tag';
import Helmet from 'react-helmet';

const BottomButtons = styled.div`
display: flex;
width: 96%;
margin-left: 2%;
align-items: center;
margin-top: 30px;
a{
  color: var(--orange);
  font-family: 'Bebas Neue';
  font-size: 24px;
  border: none;
  line-height: 30px;
  position: relative;
  transition: .5s ease-in;

  strong{
    font-weight: normal;
  }
  :hover{
    color: var(--yellow);
    :before{
      width: calc(100% + 30px);
      transition: .5s ease-in;
    height: inherit;      }

  }

  span{
    padding: 15px 10px;
    background-color: var(--orange);
    display: inline-block;
    border-radius: 15px;
    color: var(--cream);
    transition: .5s ease-in;
    width: 10px;
    line-height: 0;
    z-index: -1;
  }
}



`

const FullImg = styled.img`
width: 100%;
`



const TopImgBlog = styled.img`
width: 100%;
margin: 0;
object-fit: cover;
`

const BlogPost = ({ match }) => {
  const [prismicData, setPrismicData] = useState({ pageDoc: null, nextPost: null, prevPost: null});
  const [notFound, toggleNotFound] = useState(false);

  const uid = match.params.uid;
  const english = localStorage.getItem('eng')

  // Get the page document from Prismic
  React.useEffect(() => {
    const fetchPrismicData = async () => {
      try {

        let options;
        if(english === 'false'){
          options = { lang: 'es-mx' }
        }else options = {lang: 'en-us'};

        const pageToggle = await Client.getByUID('blog', uid, options);
        const defaultPage = await Client.getByUID('blog', uid, {lang: 'en-us'});

        const checkNext = defaultPage.id;

        const nextPost = await Client.query(
          Prismic.Predicates.at('document.type', 'blog'),
          {after: checkNext, orderings: '[document.first_publication_date]'}
        );

        const prevPost = await Client.query(
          Prismic.Predicates.at('document.type', 'blog'),
          {after: checkNext, orderings: '[document.first_publication_date desc]'}
        );

        let pageDoc;
        if(pageToggle === undefined){
          pageDoc = defaultPage;
        }else{
          pageDoc = pageToggle;
        }




        if (pageDoc) {
          setPrismicData({ pageDoc, nextPost: nextPost.results, prevPost: prevPost.results});
        } else {
          console.warn('Page document was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, [uid]);


  if (prismicData.pageDoc) {


    const prog = prismicData.pageDoc;
    const hasVid = Link.url()
    let hasImg;
    let nextButton;
    let prevButton;

    if(prismicData.nextPost.length > 0){
      nextButton = prismicData.nextPost[0].uid;
    }else{
      nextButton = null;
    }

    if(prismicData.prevPost.length > 0){
      prevButton = prismicData.prevPost[0].uid;
    }else{
      prevButton = null;
    }



    if(prog.data.top_video.url){
      hasImg =
      <div className={'player-wrapper'}>
      <ReactPlayer
        className={'react-player'}
        url='https://www.youtube.com/watch?v=SYACA-0WYts&t=2s'
        width='100%'
        height='100%'
        config={{
          youtube: {
            playerVars: { modestbranding: 1 }
          }
        }}/></div>

    }
    else if(prog.data.top_image.url){
      hasImg = <TopImgBlog src={prog.data.top_image.url}/>
    }
    else{
      hasImg = null
    }




    const pageSlices=prog.data.body.map((slice, index) => {
  // Render the right markup for the given slice type


if (slice.slice_type === 'button'){
    const ButtonHold = styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
    `
    return(


      <ButtonHold>
      <a href={Link.url(slice.primary.link, linkResolver)} className={'button1'}>{slice.primary.link_text}</a>
      </ButtonHold>
    );
  }

  else if (slice.slice_type === 'image'){
    return(
      <FullImg src={slice.primary.image.url}/>
    )
  }

  else if (slice.slice_type === 'youtube'){

    const VidToggle = () => {
    const [show, toggleShow] = React.useState(false);

    return(
      <VideoContain>
      <VideoText className={show && 'hideText'}>
      <div className={'buttons'}>
        <h3>{RichText.asText(slice.primary.header)}</h3>
        {RichText.render(slice.primary.links)}
        </div>
      </VideoText>
      <Vid className={show && 'vidExpand'}>
      <ReactPlayer
        className={'react-player'}
        url={slice.primary.video.url}
        width='100%'
        height='100%'
        onPlay={() => toggleShow(!show)}
        onPause={() => toggleShow(!show)}
        config={{
          youtube: {
            playerVars: { modestbranding: 1 }
          }
        }}/>
      </Vid>
      </VideoContain>
    )}

    return(<VidToggle/>)
  }


  else if (slice.slice_type === 'paragraph'){
    const paragraphname = 'test'
    return(
      <BlogParagraph className={'buttons2'}>
      {RichText.render(slice.primary.text)}
      </BlogParagraph>
    )
  }

  else if(slice.slice_type === 'donate_button'){
    const KindHold = styled.div`
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 30px 0;
    `

      return(
        <KindHold>
        <a className={'kindfulButton'} id={`kindful-donate-btn-` + slice.primary.id}>Donate</a>
  <ScriptTag src={`https://centrocultural.kindful.com/embeds/` + slice.primary.id + `/init.js`} data-embed-id={slice.primary.id} data-lookup-type='jquery-selector' data-lookup-value={`#kindful-donate-btn-` + slice.primary.id}></ScriptTag>
      </KindHold>
      ) }

  else if (slice.slice_type === 'paragraph-image'){
    const Ptext = styled.div`
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: var(--maroon);
      margin-bottom: 30px;
      align-items: center;
      img{
        width: calc( 50% - 15px);
        object-fit: contain;
      }

      div{
        width: 100%;
        p{
          margin: 0 0 15px;
          font-size: 14px;
        }
      }
    `

    return(
      <Ptext>
      <img src={slice.primary.image.url}/>
      <div>
      {RichText.render(slice.primary.text)}
      </div>
      </Ptext>
    )
  }


    else {
    return null;
  }
});


const today = Date(prog.data.published_date);
const formattedDate = format(today, 'PP');

// Outputs as "Fri Feb 17 2017 01:00:00 GMT+0100"
    return(
      <div>
      <GlobalStyle/>
      <TopBlock>
      {hasImg}

      </TopBlock>
      <MainBlock className={'blog'}>

      <div className={'blogHead'}><h4 className={'blogDate'}>{formattedDate}</h4><h2>{prog.data.page_title}</h2></div>
      <BlogParagraph2>
      {RichText.render(prog.data.first_paragraph)}
      </BlogParagraph2>

      {pageSlices}

      </MainBlock>
      <BottomButtons>
      {prevButton === null ? <a></a> : <a href={'/blog/' + prevButton}><span className={'blogpadLeft'}>&lt;</span>Previous Post</a>}
      <a className={'buttonBlog'} href="/blog">All Entries</a>
      {nextButton === null ? <a></a> : <a href={'/blog/' + nextButton}>Next Post<span className={'blogpadRight'}>&gt;</span></a>}
      </BottomButtons>
      </div>);
  } return null;

}

export default BlogPost;
