import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState, setState, useRef} from 'react';
import {
  TopBlock, MainBlock, GlobalStyle
} from './styles.js';
import Prismic from '@prismicio/client'
import { Date, Link, RichText } from 'prismic-reactjs'
import { Client, apiEndpoint, accessToken, linkResolver} from '../prismic-configuration';
import scrollToComponent from 'react-scroll-to-component';
import Media from 'react-media';


const TeamHold = styled.div`
display: flex;
width: 100%;
flex-wrap: wrap;
color: var(--maroon);
margin-bottom: 30px;
h2{
  width: 100%;
  font-size: 36px;
  padding-bottom:5px;
  border-bottom: 1px solid;
  margin-top: 30px;
}
`

const TeamTop = styled.div`
display: flex;
width: 100%;
justify-content: space-between;
align-items: center;
padding: 10px 0 0;
h3{
  width: 33%;

  font-size: 24px;
}
h4{
  width: calc(66% - 45px);
  font-family: 'Open Sans';
  font-weight: 600;
}
button{
  border: none;
  font-family: 'Bebas Neue';
  font-size: 28px;
  line-height:36px;
  color: #fff;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 100px;
  background-color: var(--orange);
  transform: rotate(270deg);
  transition: 0.15s linear;

  :hover{
    background-color: var(--yellow);
    cursor: pointer;
  }

  :focus{
    outline: none;
  }

  img{
    width: 33%;

  }
}

@media(max-width: 600px){
  flex-wrap: wrap;
  div{
    width: 80%;
  }
  h3{
    width: 100%;
  }
  h4{
    width: 100%;
  }
  img{
    width: 100%;
  }
}
`

const TeamBottom = styled(TeamTop)`
align-items: start;
transition: 0.5s linear;
max-height: auto;
overflow: hidden;
img{
  width: 27%;
  margin-bottom: 10px;
}

@media(max-width: 600px){
div{
  width: 100%;
}
  img{
    width: 100%;
  }
  p{
    width: 100%;
  }
}

`

const Member = styled.div`
width: 100%;
border-bottom: 1px solid;

`

const Text = styled.div`
width: calc(66%);
p{
  max-width: 700px;
  margin: 0 0 10px;
}

a{
  color: var(--orange);
  font-weight: 600;
}
`



const Team = ({match}) => {
  const [prismicData, setPrismicData] = useState({ pageDoc: null});
  const [notFound, toggleNotFound] = useState(false);

  const uid = match.params.uid;

  // Get the page document from Prismic
  React.useEffect(() => {
    const fetchPrismicData = async () => {
      try {
        const pageDoc = await Client.getByUID('team', uid);

        if (pageDoc) {
          setPrismicData({ pageDoc });
        } else {
          console.warn('Page document was not found. Make sure it exists in your Prismic repository');
          toggleNotFound(true);
        }
      } catch (error) {
        console.error(error);
        toggleNotFound(true);
      }
    }
    fetchPrismicData();

    // Load new page at the top (when linking from the middle of another page)
    window.scrollTo(0, 0);
  }, [uid]);

  const [checked, setChecked] = useState(false);

  if (prismicData.pageDoc) {
    const team = prismicData.pageDoc;

    const navSlices=team.data.body.map((slice, index) => {
      if(slice.primary.nav_text){return(
        <h3 className={'qj'} onClick={() => scrollToComponent(slice.primary.nav_text, { offset: -110, align: 'top', duration: 30})}>{slice.primary.nav_text}</h3>
      )}else return null
    });

    const teamSlices=team.data.body.map((slice, index) => {
      const block = slice.items.map((block, blockIndex) =>{

        const Toggle = () => {
          const [show, toggleShow] = React.useState(true);

          return (
            <Member>

            <Media query="(max-width: 600px)" render={() =>
              <TeamTop>
              <div>
              <h3>{block.name}</h3>
              <h4>{block.title}</h4>
              </div>
              <button className={show && 'buttonSpin'} onClick={() => toggleShow(!show)} >></button>
              </TeamTop>}
              />

              <Media query="(min-width: 601px)" render={() =>
                <TeamTop>
                <h3>{block.name}</h3>
                <h4>{block.title}</h4>
                  <button className={show && 'buttonSpin'} onClick={() => toggleShow(!show)} >></button>
                  </TeamTop>
                }
                />



            <TeamBottom className={show && 'teamHide'}>
            <img src={block.image.url}/>
            <Text>
            {RichText.render(block.description)}
            </Text>
            </TeamBottom>
            </Member>

          )
        }


        return(
          <Toggle/>

        )
      });
      return(
        <TeamHold ref={(section) => { slice.primary.nav_text = section; }}>
        <h2>{slice.primary.header1}</h2>
        {block}
        </TeamHold>
      )
    });
    console.log(team.data.quick_jump);
    return(
      <MainBlock>
      <div className={'bigHead'}><span/><h2>{team.data.header}</h2><span/></div>
      {team.data.quick_jump && <div className="quickjump">
      <h3>Quick Jump:</h3>
      {navSlices}
      </div>}
        {teamSlices}
      </MainBlock>
    );
  }else return(null);

}

export default Team;
