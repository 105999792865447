import styled, {createGlobalStyle} from 'styled-components';
import React, {useEffect, useState, Component} from 'react';
import { Date, Link, RichText } from 'prismic-reactjs';
import { Client, apiEndpoint, accessToken} from '../prismic-configuration';
import MailchimpSubscribe from "react-mailchimp-subscribe"
import { FacebookProvider, Like } from 'react-facebook';
import Iframe from 'react-iframe'

const url = "//centrocultural.us7.list-manage.com/subscribe/post?u=fa05f03020ac4167d6f24237b&amp;id=d976bfb7e6";


export const Facebook = styled.div`
  margin: 10px 0;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  @media(max-width: 700px){
    width: 100%;
    margin-top: 30px;
  }
  div{
    display: flex;
    width: 100%;
  }
`

export const FacebookText = styled.div`
font-family: beaufort;
letter-spacing: 1px;
color: var(--cream);
width: 100%;
margin: 0 !important;
p{
  font-size: 16px;
  text-align: left;
  margin: 0;
  color: var(--cream) !important;
  font-weight: normal !important;
  font-family: "Open Sans";
  @media(max-width: 700px){
    text-align: center;
    margin: 0 auto;
  }
}
`

export const FacebookClear = styled.div`
width: 100vw;
position: relative;
left: 0vw;
max-width: 100%;
`
export const FacebookClear2 = styled.div`
width: 100vw;
position: relative;
left: 0vw;
`

export const FacebookHold = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
margin: 30px 0;
left: -2vw;
background-color: var(--maroon);
padding: 20px 2vw;
position: relative;
z-index:0;
@media(max-width: 700px){
  flex-wrap: wrap;
}

@media(max-width: 600px){
  left: -5vw;
  padding: 60px 5vw;
}
`

export const FbInfo = styled.div`
color: var(--cream);
width: 45%;
font-weight: normal;
padding: 0 2% 0 4%;
@media(max-width: 700px){
  width: 100%;
}
h3{
  font-size: 42px;
  margin: 0;
  color: var(--yellow);
}

p{
  font-size: 16px;
  font-family: "Open Sans";
  color: var(--rust);
  margin: 0;
}

h6{
  margin-top: 15px;
}
`

export const Squares = styled.div`
width: 100%;
background-color: var(--maroon);
mask: url('square.svg');
fill: var(--cream);
height: 20px;
position: absolute;
top: -20px;
left: 0;

`

export const Squares2 = styled.div`
width: 100%;
background-color: var(--maroon);
mask: url('square2.svg');
fill: var(--cream);
height: 20px;
position: absolute;
bottom: -20px;
left: 0;
`




const CustomForm = ({ status, message, onValidated }) => {
  let email;
  let fname;
  let lname;
  const submit = () =>
    email &&
    fname &&
    lname &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
      FNAME: fname.value,
      LNAME: lname.value
    });

  return (
    <FacebookClear>
    <FacebookHold>
    <Squares/>
    <FbInfo>
    <h3>Visit our Facebook</h3>

    <p>We'll periodically send you the latest information about what's happening at Centro, including volunteer opportunities, events, and other news.</p>
    </FbInfo>

    <Facebook>
    <Iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FProsperidadCentro%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=833893947539216" width="340" height="500" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></Iframe>
      </Facebook>
      <Squares2/>
    </FacebookHold>
    </FacebookClear>
  );
};



const FaceBlock = () => {
return(
  <MailchimpSubscribe
  url={url}
  render={({ subscribe, status, message }) => (
    <CustomForm
      status={status}
      message={message}
      onValidated={formData => subscribe(formData)}
    />
  )}
  />

);
};

export default FaceBlock;
