import Prismic from 'prismic-javascript'
import { Date, Link, RichText } from 'prismic-reactjs'

export const apiEndpoint = 'https://centro-cultural.cdn.prismic.io/api/v2'
export const accessToken = '' // This is where you would add your access token for a Private repository

export const Client = Prismic.client(apiEndpoint, { accessToken })

const defaultLanguage = 'en-us'

export const linkResolver = (doc) => {
  // URL for a category type
  if (doc.type === 'program') {
    return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.uid}`
  }

  else if (doc.type === 'team'){
    return `/team/${doc.uid}`
  }
  else if (doc.type === 'blog_index'){
    return `/blog`
  }
  // Backup for all other types
  return '/'
}

export default {
  apiEndpoint: 'https://centro-cultural.cdn.prismic.io/api/v2',

  linkResolver(doc) {
    if (doc.type === 'program') {
      return doc.lang === defaultLanguage ? `/${doc.uid}` : `/${doc.uid}`
    }

    else if (doc.type === 'team'){
      return `/team/${doc.uid}`
    }
    else if (doc.type === 'blog_index'){
      return `/blog`
    }
    // Backup for all other types
    return '/'
  },

}
